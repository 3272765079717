<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" @click="copy">
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <span>in Zwischenablage kopieren</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ['value'],
  methods: {
    async copy() {
      try {
        await navigator.clipboard.writeText(this.value);
        this.$root.showSuccess(
          '<strong>in Zwischenablage kopiert:</strong><br>' + this.value
        );
      } catch (err) {
        this.$root.showError('Fehler beim Kopieren in die Zwischenablage');
      }
    },
  },
};
</script>
