export function getEmailClient() {
  return localStorage.getItem('emailClient') || 'mailto';
}
export function setEmailClient(emailClient) {
  localStorage.setItem('emailClient', emailClient);
}
export function getPeopleSort() {
  return localStorage.getItem('peopleSort') || 'lastName';
}
export function setPeopleSort(personSort) {
  localStorage.setItem('peopleSort', personSort);
}
export function getItemsPerPage() {
  return Number.parseInt(localStorage.getItem('itemsPerPage')) || 15;
}
export function setItemsPerPage(itemsPerPage) {
  localStorage.setItem('itemsPerPage', itemsPerPage);
}
