import Vue from 'vue';
export const bus = new Vue();

export function updateEmailClient(client) {
  bus.$emit('updateEmailClient', client);
}

export function updatePeopleSort() {
  bus.$emit('updatePeopleSort');
}
